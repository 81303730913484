
.Toastify__toast-container {
	z-index: 9999;
	position: fixed;
	padding: 4px;
	width: 320px;
	box-sizing: border-box;
    color: #fff;
    display: none;
}
.Toastify__toast-container.success, .Toastify__toast-container.error {
    display: block !important;
}

.Toastify__toast-container--top-left {
	top: 1em;
	left: 1em
}

.Toastify__toast-container--top-center {
	top: 1em;
	left: 50%;
	margin-left: -160px
}

.Toastify__toast-container--top-right {
	top: 1em;
	right: 1em
}

.Toastify__toast-container--bottom-left {
	bottom: 1em;
	left: 1em
}

.Toastify__toast-container--bottom-center {
	bottom: 1em;
	left: 50%;
	margin-left: -160px
}

.Toastify__toast-container--bottom-right {
	bottom: 1em;
	right: 1em
}

@media only screen and (max-width:480px) {
	.Toastify__toast-container {
		width: 100vw;
		padding: 0;
		left: 0;
		margin: 0
	}
	.Toastify__toast-container--top-center,
	.Toastify__toast-container--top-left,
	.Toastify__toast-container--top-right {
		top: 0
	}
	.Toastify__toast-container--bottom-center,
	.Toastify__toast-container--bottom-left,
	.Toastify__toast-container--bottom-right {
		bottom: 0
	}
	.Toastify__toast-container--rtl {
		right: 0;
		left: auto
	}
}

.Toastify__toast {
	position: relative;
	min-height: 64px;
	box-sizing: border-box;
	margin-bottom: 1rem;
	padding: 8px;
	border-radius: 1px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
	display: flex;
	justify-content: space-between;
	max-height: 800px;
	overflow: hidden;
	font-family: sans-serif;
	cursor: pointer;
	direction: ltr
}

.Toastify__toast--rtl {
	direction: rtl
}

.Toastify__toast--default {
	background: #fff;
	color: #aaa
}

.Toastify__toast--info {
	background: #3498db
}

.Toastify__toast--success {
	background: #07bc0c
}

.Toastify__toast--warning {
	background: #f1c40f
}

.Toastify__toast--error {
	background: #e74c3c
}

.Toastify__toast-body {
	margin: auto 0;
	flex: 1 1
}

@media only screen and (max-width:480px) {
	.Toastify__toast {
		margin-bottom: 0
	}
}

.Toastify__close-button {
	color: #fff;
	font-weight: 700;
	font-size: 14px;
	background: 0 0;
	outline: none;
	border: none;
	padding: 0;
	cursor: pointer;
	opacity: .7;
	-webkit-transition: .3s ease;
	transition: .3s ease;
	align-self: flex-start
}

.Toastify__close-button--default {
	color: #000;
	opacity: .3
}

.Toastify__close-button:focus,
.Toastify__close-button:hover {
	opacity: 1
}

@-webkit-keyframes Toastify__trackProgress {
	0% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1)
	}
	to {
		-webkit-transform: scaleX(0);
		transform: scaleX(0)
	}
}

@keyframes Toastify__trackProgress {
	0% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1)
	}
	to {
		-webkit-transform: scaleX(0);
		transform: scaleX(0)
	}
}

.Toastify__progress-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	z-index: 9999;
	opacity: .7;
	-webkit-animation: Toastify__trackProgress linear 1 forwards;
	animation: Toastify__trackProgress linear 1 forwards;
	background-color: hsla(0, 0%, 100%, .7);
	-webkit-transform-origin: left;
	transform-origin: left
}

.Toastify__progress-bar--rtl {
	right: 0;
	left: auto;
	-webkit-transform-origin: right;
	transform-origin: right
}

.Toastify__progress-bar--default {
	background: -webkit-linear-gradient(left, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
	background: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)
}

@-webkit-keyframes Toastify__bounceInRight {
	0%,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(3000px, 0, 0);
		transform: translate3d(3000px, 0, 0)
	}
	60% {
		opacity: 1;
		-webkit-transform: translate3d(-25px, 0, 0);
		transform: translate3d(-25px, 0, 0)
	}
	75% {
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0)
	}
	90% {
		-webkit-transform: translate3d(-5px, 0, 0);
		transform: translate3d(-5px, 0, 0)
	}
	to {
		-webkit-transform: none;
		transform: none
	}
}

@keyframes Toastify__bounceInRight {
	0%,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(3000px, 0, 0);
		transform: translate3d(3000px, 0, 0)
	}
	60% {
		opacity: 1;
		-webkit-transform: translate3d(-25px, 0, 0);
		transform: translate3d(-25px, 0, 0)
	}
	75% {
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0)
	}
	90% {
		-webkit-transform: translate3d(-5px, 0, 0);
		transform: translate3d(-5px, 0, 0)
	}
	to {
		-webkit-transform: none;
		transform: none
	}
}

@-webkit-keyframes Toastify__bounceOutRight {
	20% {
		opacity: 1;
		-webkit-transform: translate3d(-20px, 0, 0);
		transform: translate3d(-20px, 0, 0)
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0)
	}
}

@keyframes Toastify__bounceOutRight {
	20% {
		opacity: 1;
		-webkit-transform: translate3d(-20px, 0, 0);
		transform: translate3d(-20px, 0, 0)
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0)
	}
}

@-webkit-keyframes Toastify__bounceInLeft {
	0%,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-3000px, 0, 0);
		transform: translate3d(-3000px, 0, 0)
	}
	60% {
		opacity: 1;
		-webkit-transform: translate3d(25px, 0, 0);
		transform: translate3d(25px, 0, 0)
	}
	75% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0)
	}
	90% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0)
	}
	to {
		-webkit-transform: none;
		transform: none
	}
}

@keyframes Toastify__bounceInLeft {
	0%,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-3000px, 0, 0);
		transform: translate3d(-3000px, 0, 0)
	}
	60% {
		opacity: 1;
		-webkit-transform: translate3d(25px, 0, 0);
		transform: translate3d(25px, 0, 0)
	}
	75% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0)
	}
	90% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0)
	}
	to {
		-webkit-transform: none;
		transform: none
	}
}

@-webkit-keyframes Toastify__bounceOutLeft {
	20% {
		opacity: 1;
		-webkit-transform: translate3d(20px, 0, 0);
		transform: translate3d(20px, 0, 0)
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(-2000px, 0, 0);
		transform: translate3d(-2000px, 0, 0)
	}
}

@keyframes Toastify__bounceOutLeft {
	20% {
		opacity: 1;
		-webkit-transform: translate3d(20px, 0, 0);
		transform: translate3d(20px, 0, 0)
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(-2000px, 0, 0);
		transform: translate3d(-2000px, 0, 0)
	}
}

@-webkit-keyframes Toastify__bounceInUp {
	0%,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 3000px, 0);
		transform: translate3d(0, 3000px, 0)
	}
	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0)
	}
	75% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0)
	}
	90% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0)
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@keyframes Toastify__bounceInUp {
	0%,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 3000px, 0);
		transform: translate3d(0, 3000px, 0)
	}
	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0)
	}
	75% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0)
	}
	90% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0)
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@-webkit-keyframes Toastify__bounceOutUp {
	20% {
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0)
	}
	40%,
	45% {
		opacity: 1;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0)
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -2000px, 0);
		transform: translate3d(0, -2000px, 0)
	}
}

@keyframes Toastify__bounceOutUp {
	20% {
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0)
	}
	40%,
	45% {
		opacity: 1;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0)
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -2000px, 0);
		transform: translate3d(0, -2000px, 0)
	}
}

@-webkit-keyframes Toastify__bounceInDown {
	0%,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -3000px, 0);
		transform: translate3d(0, -3000px, 0)
	}
	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, 25px, 0);
		transform: translate3d(0, 25px, 0)
	}
	75% {
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0)
	}
	90% {
		-webkit-transform: translate3d(0, 5px, 0);
		transform: translate3d(0, 5px, 0)
	}
	to {
		-webkit-transform: none;
		transform: none
	}
}

@keyframes Toastify__bounceInDown {
	0%,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -3000px, 0);
		transform: translate3d(0, -3000px, 0)
	}
	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, 25px, 0);
		transform: translate3d(0, 25px, 0)
	}
	75% {
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0)
	}
	90% {
		-webkit-transform: translate3d(0, 5px, 0);
		transform: translate3d(0, 5px, 0)
	}
	to {
		-webkit-transform: none;
		transform: none
	}
}

@-webkit-keyframes Toastify__bounceOutDown {
	20% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0)
	}
	40%,
	45% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0)
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 2000px, 0);
		transform: translate3d(0, 2000px, 0)
	}
}

@keyframes Toastify__bounceOutDown {
	20% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0)
	}
	40%,
	45% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0)
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 2000px, 0);
		transform: translate3d(0, 2000px, 0)
	}
}

.Toastify__bounce-enter--bottom-left,
.Toastify__bounce-enter--top-left {
	-webkit-animation-name: Toastify__bounceInLeft;
	animation-name: Toastify__bounceInLeft
}

.Toastify__bounce-enter--bottom-right,
.Toastify__bounce-enter--top-right {
	-webkit-animation-name: Toastify__bounceInRight;
	animation-name: Toastify__bounceInRight
}

.Toastify__bounce-enter--top-center {
	-webkit-animation-name: Toastify__bounceInDown;
	animation-name: Toastify__bounceInDown
}

.Toastify__bounce-enter--bottom-center {
	-webkit-animation-name: Toastify__bounceInUp;
	animation-name: Toastify__bounceInUp
}

.Toastify__bounce-exit--bottom-left,
.Toastify__bounce-exit--top-left {
	-webkit-animation-name: Toastify__bounceOutLeft;
	animation-name: Toastify__bounceOutLeft
}

.Toastify__bounce-exit--bottom-right,
.Toastify__bounce-exit--top-right {
	-webkit-animation-name: Toastify__bounceOutRight;
	animation-name: Toastify__bounceOutRight
}

.Toastify__bounce-exit--top-center {
	-webkit-animation-name: Toastify__bounceOutUp;
	animation-name: Toastify__bounceOutUp
}

.Toastify__bounce-exit--bottom-center {
	-webkit-animation-name: Toastify__bounceOutDown;
	animation-name: Toastify__bounceOutDown
}

@-webkit-keyframes Toastify__zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3)
	}
	50% {
		opacity: 1
	}
}

@keyframes Toastify__zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3)
	}
	50% {
		opacity: 1
	}
}

@-webkit-keyframes Toastify__zoomOut {
	0% {
		opacity: 1
	}
	50% {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3)
	}
	to {
		opacity: 0
	}
}

@keyframes Toastify__zoomOut {
	0% {
		opacity: 1
	}
	50% {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3)
	}
	to {
		opacity: 0
	}
}

.Toastify__zoom-enter {
	-webkit-animation-name: Toastify__zoomIn;
	animation-name: Toastify__zoomIn
}

.Toastify__zoom-exit {
	-webkit-animation-name: Toastify__zoomOut;
	animation-name: Toastify__zoomOut
}

@-webkit-keyframes Toastify__flipIn {
	0% {
		-webkit-transform: perspective(400px) rotateX(90deg);
		transform: perspective(400px) rotateX(90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0
	}
	40% {
		-webkit-transform: perspective(400px) rotateX(-20deg);
		transform: perspective(400px) rotateX(-20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in
	}
	60% {
		-webkit-transform: perspective(400px) rotateX(10deg);
		transform: perspective(400px) rotateX(10deg);
		opacity: 1
	}
	80% {
		-webkit-transform: perspective(400px) rotateX(-5deg);
		transform: perspective(400px) rotateX(-5deg)
	}
	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px)
	}
}

@keyframes Toastify__flipIn {
	0% {
		-webkit-transform: perspective(400px) rotateX(90deg);
		transform: perspective(400px) rotateX(90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0
	}
	40% {
		-webkit-transform: perspective(400px) rotateX(-20deg);
		transform: perspective(400px) rotateX(-20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in
	}
	60% {
		-webkit-transform: perspective(400px) rotateX(10deg);
		transform: perspective(400px) rotateX(10deg);
		opacity: 1
	}
	80% {
		-webkit-transform: perspective(400px) rotateX(-5deg);
		transform: perspective(400px) rotateX(-5deg)
	}
	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px)
	}
}

@-webkit-keyframes Toastify__flipOut {
	0% {
		-webkit-transform: perspective(400px);
		transform: perspective(400px)
	}
	30% {
		-webkit-transform: perspective(400px) rotateX(-20deg);
		transform: perspective(400px) rotateX(-20deg);
		opacity: 1
	}
	to {
		-webkit-transform: perspective(400px) rotateX(90deg);
		transform: perspective(400px) rotateX(90deg);
		opacity: 0
	}
}

@keyframes Toastify__flipOut {
	0% {
		-webkit-transform: perspective(400px);
		transform: perspective(400px)
	}
	30% {
		-webkit-transform: perspective(400px) rotateX(-20deg);
		transform: perspective(400px) rotateX(-20deg);
		opacity: 1
	}
	to {
		-webkit-transform: perspective(400px) rotateX(90deg);
		transform: perspective(400px) rotateX(90deg);
		opacity: 0
	}
}

.Toastify__flip-enter {
	-webkit-animation-name: Toastify__flipIn;
	animation-name: Toastify__flipIn
}

.Toastify__flip-exit {
	-webkit-animation-name: Toastify__flipOut;
	animation-name: Toastify__flipOut
}

@-webkit-keyframes Toastify__slideInRight {
	0% {
		-webkit-transform: translate3d(110%, 0, 0);
		transform: translate3d(110%, 0, 0);
		visibility: visible
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@keyframes Toastify__slideInRight {
	0% {
		-webkit-transform: translate3d(110%, 0, 0);
		transform: translate3d(110%, 0, 0);
		visibility: visible
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@-webkit-keyframes Toastify__slideInLeft {
	0% {
		-webkit-transform: translate3d(-110%, 0, 0);
		transform: translate3d(-110%, 0, 0);
		visibility: visible
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@keyframes Toastify__slideInLeft {
	0% {
		-webkit-transform: translate3d(-110%, 0, 0);
		transform: translate3d(-110%, 0, 0);
		visibility: visible
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@-webkit-keyframes Toastify__slideInUp {
	0% {
		-webkit-transform: translate3d(0, 110%, 0);
		transform: translate3d(0, 110%, 0);
		visibility: visible
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@keyframes Toastify__slideInUp {
	0% {
		-webkit-transform: translate3d(0, 110%, 0);
		transform: translate3d(0, 110%, 0);
		visibility: visible
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@-webkit-keyframes Toastify__slideInDown {
	0% {
		-webkit-transform: translate3d(0, -110%, 0);
		transform: translate3d(0, -110%, 0);
		visibility: visible
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@keyframes Toastify__slideInDown {
	0% {
		-webkit-transform: translate3d(0, -110%, 0);
		transform: translate3d(0, -110%, 0);
		visibility: visible
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
}

@-webkit-keyframes Toastify__slideOutRight {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
	to {
		visibility: hidden;
		-webkit-transform: translate3d(110%, 0, 0);
		transform: translate3d(110%, 0, 0)
	}
}

@keyframes Toastify__slideOutRight {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
	to {
		visibility: hidden;
		-webkit-transform: translate3d(110%, 0, 0);
		transform: translate3d(110%, 0, 0)
	}
}

@-webkit-keyframes Toastify__slideOutLeft {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
	to {
		visibility: hidden;
		-webkit-transform: translate3d(-110%, 0, 0);
		transform: translate3d(-110%, 0, 0)
	}
}

@keyframes Toastify__slideOutLeft {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
	to {
		visibility: hidden;
		-webkit-transform: translate3d(-110%, 0, 0);
		transform: translate3d(-110%, 0, 0)
	}
}

@-webkit-keyframes Toastify__slideOutDown {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, 500px, 0);
		transform: translate3d(0, 500px, 0)
	}
}

@keyframes Toastify__slideOutDown {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, 500px, 0);
		transform: translate3d(0, 500px, 0)
	}
}

@-webkit-keyframes Toastify__slideOutUp {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -500px, 0);
		transform: translate3d(0, -500px, 0)
	}
}

@keyframes Toastify__slideOutUp {
	0% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}
	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -500px, 0);
		transform: translate3d(0, -500px, 0)
	}
}

.Toastify__slide-enter--bottom-left,
.Toastify__slide-enter--top-left {
	-webkit-animation-name: Toastify__slideInLeft;
	animation-name: Toastify__slideInLeft
}

.Toastify__slide-enter--bottom-right,
.Toastify__slide-enter--top-right {
	-webkit-animation-name: Toastify__slideInRight;
	animation-name: Toastify__slideInRight
}

.Toastify__slide-enter--top-center {
	-webkit-animation-name: Toastify__slideInDown;
	animation-name: Toastify__slideInDown
}

.Toastify__slide-enter--bottom-center {
	-webkit-animation-name: Toastify__slideInUp;
	animation-name: Toastify__slideInUp
}

.Toastify__slide-exit--bottom-left,
.Toastify__slide-exit--top-left {
	-webkit-animation-name: Toastify__slideOutLeft;
	animation-name: Toastify__slideOutLeft
}

.Toastify__slide-exit--bottom-right,
.Toastify__slide-exit--top-right {
	-webkit-animation-name: Toastify__slideOutRight;
	animation-name: Toastify__slideOutRight
}

.Toastify__slide-exit--top-center {
	-webkit-animation-name: Toastify__slideOutUp;
	animation-name: Toastify__slideOutUp
}

.Toastify__slide-exit--bottom-center {
	-webkit-animation-name: Toastify__slideOutDown;
	animation-name: Toastify__slideOutDown
}
