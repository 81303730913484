.sidebar, .sidebar-minimized .sidebar .nav-link:hover{
    background: #fff;
}
.sidebar .nav-link:hover, .sidebar .nav-link {
    color: #3a4248;
}
.sidebar .nav-link:hover, .sidebar .nav-link.active {
    color: #fff !important;
    background: #3a4248 !important;
}
.sidebar .nav-link:hover, .sidebar .nav-link.active .nav-icon {
    color: #fff;
}
.sidebar .nav-dropdown.open .nav-link{
	color: #3a4248;
    background: #dadada;
}
.sidebar .nav-dropdown.open .nav-dropdown-items .nav-link{
    padding-left: 35px;
}
.sidebar .nav-dropdown.open {
    background: transparent;
}
.sidebar .nav{
    border-right: 1px solid #e4e5e6;
}
.nav-item{
    border-bottom: 1px solid #e4e5e6;
}
.navbar-brand img{
    max-height: 100%;
    margin-top: 0px;
}
.sidebar .sidebar-minimizer{
    background-color: rgb(58, 66, 72);
}

.sidebar .nav-dropdown-toggle::before{
    transform: rotate(-180deg)
}
.sidebar .nav-link .nav-icon{
    margin: 0 1.5rem 0 0;
    font-size: 1.5rem;
}
.card-header-action{
    cursor: pointer
}
.main{
    background-color: #dadada;
}
.main .container-fluid{
    padding: 0 20px 20px 20px;
}

[class*='col-']{
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.panel{
    margin-bottom: 0px !important;
    border: none !important;
}
.panel-heading{
    padding: 13px 15px !important;
}

.card{
    border-radius: 0;
    border: none !important;
    margin-bottom: 0 !important
}
.card-box{
    border-right: 1px solid #dadada !important;
}
.loading{   width: 100%;    height: 100%;    z-index: 9999;    position: fixed;    background: #FFF;    opacity: 0.7;}
.loader{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-app-box{
    border: 1px dashed #d4d8d9;
    color: #abaeb0;
    background: #f6f6f8;
    font-weight: bold;
    cursor: pointer
}

body{
    color: #3a4248;
}
.title{
    color: #3a4248;
    font-weight: bold;
}
.date-selected {
    color: #ff5722 !important;
}
.popover {
    max-width: 700px;
}

.date-range-output {
    color: #2d353e;
    cursor: pointer;
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    zoom: 1;
    font-size: 13px;
    font-weight: 800;
    margin-top: 9px;
}
.date-range-output .icon-calendar{
        padding-right: 10px;
}
.date-range-output .desktop-only{
    padding-right: 10px;
}
.date-range-output .btn{
        padding: 0;
    background: none;
    border: none;
}

.DateRangePicker__CalendarSelection {
    background-color: #2cbcff;
    border: 1px solid #2cbcff;
}

.display-4{
    font-size: 2rem
}

.custom-listing{
    border-radius: 0px;
    overflow: hidden;
    max-height: 175px;
    overflow-y: auto;
    position:relative;
}
.custom-listing li{
    padding:2px 10px;
    border-radius: 0px;
}
.list-group-item.bdr-none:first-child{
    border:none;
}

.custom-user-listing li span{
    display:block
}
.custom-user-listing li span:first-child{
    width:30%;
}
.custom-user-listing li span:nth-child(2){
    width:20%;
}
.custom-user-listing li span:nth-child(3){
    width:25%;
}
.custom-user-listing li span:nth-child(4){
    width:10%;
}
.custom-user-listing li span:nth-child(5){
    width:25%;
}
.custom-user-listing li span:nth-child(6){
    width:10%;
}

.no-underline, .no-underline:hover{
    text-decoration: none;
}

.btn-outline-primary{
    border-color: #20a8d8 !important;
}
.btn-outline-primary:hover{
    color: #ffffff !important;
}
.btn-outline-primary:focus{
    outline: none !important;
}
.validation-error {
    padding: 4px;
    color: #d5563b;
    font-size: 13px;
}
.search-icon{
    position: absolute;
    top: 2px;
    right: 20px;
    height: 30px;
    width: 20px !important;
    cursor: pointer;
}
.copy-icon{
    border: 1px solid #20a8d8;
    padding: 2px;
    margin-left: 6px;
    border-radius: 2px;
    color: #20a8d8;
    cursor: pointer;
}
.pointer{
    cursor: pointer;
}
.uninstall-user-wrapper{
    overflow: scroll;
}
.uninstall-user-map{
    width: 1650px;
}
.uninstall-user-map-table{
    width: 100%;
    margin-bottom: 15px;
}
.uninstall-user-map-table th {
    border: 1px solid black;
    padding: 5px;
    text-align: center;
    background-color: #f0f3f5;
}
.uninstall-user-map-table td {
    border: 1px solid black;
    padding: 5px;
    text-align: center;
}
.uninstall-user-map-div{
    text-align: center;
    margin: 25px;
}
.full-container.container{
    width:100%;
    max-width:100%;
}
.border-blue{border:1px solid #177bbb !important;margin-bottom:10px !important;}
.m-b30{margin-bottom:30px;}
.border-blue .h3{color:#1d89cf;font-size:25px;}
.border-blue .panel-heading{min-height:70px;display:flex;align-items:center;}
.border-blue .panel-heading>div{text-align:center;width:100%;font-size:16px;}

.flex-box{display: flex;}
.flex-box .card{width: 100%;}
#selectedWeek{width: 74%; float: right;}

.selected-time{
    cursor: pointer;
    color: #2e51a2;
    font-weight: bold;
}.unselected-time{
    cursor: pointer;
}
.chart-wrapper{
    font-family: Arial, Helvetica, sans-serif;
}
.chart-with-border {
    border: 1px solid #ddd;
}
.chart-wrapper h5{
    color: #7e7e7e;
    margin: 0
}
.active-device-user .bar {
    overflow: hidden;
}
.active-device-user .bar span{
    background:#2e51a2;
    height: 10px;
    display: inline-block;
    width: 50%
}
.active-device-user .bar span:first-child{
    background: #9fc24e;
}
.active-device-user .android{  background: #9fc24e; width: 12px;
    height: 10px; display: inline-block; margin-right: 3px}
.active-device-user .ios{  background: #2e51a2; width: 12px;
    height: 10px; display: inline-block;margin-right: 3px; margin-left: 10px;}

    .active-device-user  .total{font-size: 40px; color:#2e51a2 }

.green-hr{
    border-color: rgb(176, 236, 224);
    margin: 10px -13px;
}
.total-device-user{
    margin: 61px 10px 0 0;
}
.total-device-user div{
    text-align: right;
    margin-bottom: 20px;
}
.total-device-user div span{
    font-size: 18px;
    display: block;
    line-height: 1.2;
}
.total-device-user div span:first-child{
    color: #2e51a2;
}
.vertical-hr{
    border-right: rgb(176, 236, 224) 1px solid;
}
.noPadding{
    padding: 0!important;
}
.active-user-table .table tbody tr:first-child td{
    border: 0;
}
.active-user-table .table{
    margin: 0
}
.fa-play.T{transform: rotate(-90deg); color: #9fc24e}
.fa-play.B{transform: rotate(90deg); color: #ff0000}
.fa-play.N{display: none;}

.fa-play.uninstallT{transform: rotate(-90deg); color: #ff0000}
.fa-play.uninstallB{transform: rotate(90deg); color: #9fc24e}
.fa-play.uninstallN{display: none;}


.active-user-table .table{margin-bottom: 0!important;}
.active-user-table .table td{padding:12px 8px;}

.chart-wrapper h4{
    text-align: left;
    color: #2e51a2;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    padding-left: 1.5rem;
}
.highcharts-container {
    width:100% !important;
    height:100% !important;
}
.highcharts-map-series .highcharts-point{
    fill:#9fc24e;
}
.highcharts-map-series .highcharts-null-point{
    fill:#1390d0;
}
.top-search{
    background: #64dcc1;
    padding: 10px 20px;
}
.top-search label{
    color: #fff;
    padding: 4px 10px;
    font-size: 18px;
    font-weight: normal;
}
.border-dropdown-large.btn, .border-dropdown-large.btn:focus, .border-dropdown-large.btn:active, .border-dropdown-large.btn:hover {
    background: none!important;
    color: #fff!important;
    border: none!important;
    border-bottom: 1px solid #fff!important;
    box-shadow: none!important;
    outline:none!important;
    border-radius:0;
    font-size: 14px;
    font-weight: normal;
    padding-left: 0;
}
.border-dropdown-large.btn::after{
    margin-left: 50px;
}
.white-input .form-control{
    background: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    border: none;
    color: #fff;
    border-bottom: 1px solid #fff;
}
.white-input .form-control::-webkit-input-placeholder { /* Edge */
    color: #fff;
  }

  .white-input .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
  }

  .white-input .form-control::placeholder {
    color: #fff;
  }
@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .active-user-table .table{
        font-size: 11px;
    }
    .active-user-table .table td{
        padding: 5px;
    }
    .total-device-user {
        margin: 64px 10px 0 0;
    }
    .w-laptop-33{
        width: 33.33%!important;
        flex: 0 0 33.33%!important;
        max-width: 33.33%!important;
    }
    .w-laptop-50{
        width: 50%!important;
        flex: 0 0 50%!important;
        max-width: 50%!important;
    }
    .w-laptop-100{
        width: 100%!important;
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .total-device-user div span{
        font-size: 16px;
    }
    .topUninstallScreens-table.table{
        font-size: 11px;
        margin-bottom: 0
    }
    .topUninstallScreens-table.table td{
        padding:4px 5px;
    }
    .active-device-user span.android-parent, .active-device-user span.ios-parent{
        display: block;
    }
    .active-device-user span.ios-parent span{
        margin-left: 0
    }
    .total-device-user {
        margin: 0px 10px 29px 0;
        text-align: center;
    }
    .total-device-user div {
        text-align: center;
        margin: 0 20px;
        display: inline-block;
    }
}
@media only screen and (max-width: 767px){
    .active-device-user span.android-parent, .active-device-user span.ios-parent{
        display: block;
    }
    .active-device-user span.ios-parent span{
        margin-left: 0
    }
    .total-device-user {
        margin: 0px 10px 29px 0;
        text-align: center;
    }
    .total-device-user div {
        text-align: center;
        margin: 0 20px;
        display: inline-block;
    }
    .active-user-table .table td:first-child{
        width: 40%!important
    }
    .active-user-table .table td:last-child{
        width: 30%!important
    }
    .vertical-hr{
        border: none;
    }
}

.country-dropdown{
    height: 0px;
    min-height: 500px !important;
    overflow: auto;
}

.search-btn{
    padding: 10px;
    color: #fff;
    background: #64dcc1;
    float: right;
    margin-left: 10px;
    color: #fff;
    box-shadow: 2px 2px 5px #a09c9c;
    border: none;
    border-radius: 4px;
}

.scrollable-flex-box{
    overflow: scroll;
    display:flex;
    width:100%;
}
.scrollable-flex-box > div{
    flex: 0 0 300px;
    padding-right: 10px;
}

sticky-table-wrapper{
    width: "100%";
    min-height: "60%";
}

.sticky-table-table{width: 100%;}


.converted-user-pagination {
    position: absolute;
    left: 0;
}


.converted-user-pagination > .pagination > li > a {
    color: #333;
}
