// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";
@import "map";
@import "toast";
@import "bootstrap";

// ie fixes
@import "ie-fix";

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.m-b {
  margin-bottom: 12px;
}
.fr {
  float: right;
}
.p-b0 {
  padding-bottom: 0;
}
.popover {
  z-index: 1060;
  top: 40px !important;
}
.bx_1 {
  border: 2px solid #64dcc1;
  border-radius: 5px;
  padding: 10px 10px;
  text-align: center;
}
.bx_1 h3 {
  color: #7e7e7e;
  margin: 5px 0px 10px 0;
}
.bx_1 .chart-wrapper p,
.bx_1 h6 {
  font-size: 13px;
}
/**
** .sticky-table for analytics funnel - Added by Mukesh Singh (mukesh.singh@gate6.com)
**/
.sticky-card-header {
  position: fixed;
  width: 100%;
  height: 114px;
  background-color: #ccc;
  padding-top: 2rem;
  top: 5rem;
}
.sticky-table {
  margin-top: 8rem;
}
.sticky-table thead {
  position: fixed;
  top: 10.5rem;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
// th {
//   min-width: 100px;
// }
// td {
//   min-width: 130px;
// }

@media (min-width: 320px) and (max-width: 480px) {
  .containerBX1.d-flex {
    flex-direction: column;
  }
  .bx_1 {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
  .sticky-card-header {
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
    height: inherit;
    top: 0;
    padding-top: 0px;
  }
  .sticky-table {
    margin-top: 0;
  }
  .sticky-table thead {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
  }
}
